import moment from "moment-timezone";
import "moment/locale/de";
import { useSupportedLocale } from "./useLocale";

function useMoment() {
  const locale = useSupportedLocale();
  moment.locale(locale);
  return moment;
}

export { useMoment };
