"use client";
import { SourcePage } from "@/analytics/sourcePage";
import { useUserSession } from "@/contexts/UserSession";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import { Dialog, Flex, Inset, Text, VisuallyHidden } from "@radix-ui/themes";
import { X } from "lucide-react";
import { useTranslations } from "next-intl";
import React, { MouseEventHandler, useEffect } from "react";
import { ProfileContactHeader } from "../Profile/ProfileContact/ProfileContactHeader";
import Favorite from "./Favorite";
import styles from "./FavoriteButtonAuthPrompt.module.scss";
import { useFavorites } from "./FavoritesContext";
import { auth } from "@/analytics";

export type FavoriteButtonAuthPromptProps = {
  picture_url: string;
  full_name: string;
  jobtitle: string;
  therapist_user_id: string;
  therapist_first_name: string;
  currentPage: SourcePage;
  isCTA?: boolean;
};

function FavoriteButtonAuthPrompt({
  picture_url,
  full_name,
  jobtitle,
  therapist_user_id,
  therapist_first_name,
  currentPage,
  isCTA,
}: FavoriteButtonAuthPromptProps) {
  const t = useTranslations("Favorites");
  const [open, setOpen] = React.useState(false);
  const handleChildClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <Dialog.Root onOpenChange={setOpen} open={open}>
      <Dialog.Trigger>
        <Favorite
          tooltipText={t("tooltip_save_to_favorites")}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(true);
          }}
          isCTA={isCTA}
        />
      </Dialog.Trigger>
      <Dialog.Content
        className={styles.dialogContainer}
        aria-describedby={undefined}
        maxWidth={"450px"}
        onClick={handleChildClick}
      >
        <VisuallyHidden>
          <Dialog.Title></Dialog.Title>
        </VisuallyHidden>

        <Inset side={"all"} clip={"border-box"}>
          <Flex direction={"column"}>
            <Flex className={styles.dialogHeader} p={"3"}>
              <ProfileContactHeader
                picture_url={picture_url}
                full_name={full_name}
                jobtitle={jobtitle}
              />
              <Flex justify={"end"}>
                <Dialog.Close>
                  <ButtonV2 radius="full" color={"gray"} variant={"ghost"}>
                    <X size={40} strokeWidth={"1"} />
                  </ButtonV2>
                </Dialog.Close>
              </Flex>
            </Flex>

            <AuthContent
              name={therapist_first_name}
              userId={therapist_user_id}
              currentPage={currentPage}
            />
          </Flex>
        </Inset>
      </Dialog.Content>
    </Dialog.Root>
  );
}

type AuthContentProps = {
  userId: string;
  name: string;
  currentPage: SourcePage;
};

function AuthContent({ userId, name, currentPage }: AuthContentProps) {
  const t = useTranslations("Favorites");
  const { openLoginPopup, openSignupPopup } = useUserSession();
  const { setPendingFavorite } = useFavorites();
  const returnTo = new URL(window.location.href);
  returnTo.searchParams.set("contact", "1");

  useEffect(() => {
    auth.trackAuthPromptViewed({
      therapist_user_id: userId,
      source_page: "therapist_profile_page",
      source_flow: "favorite",
    });
  }, [userId]);

  return (
    <Flex
      direction={"column"}
      flexGrow={"1"}
      flexShrink={"0"}
      flexBasis={"200px"}
      justify={"center"}
      align={"center"}
      p={"5"}
    >
      <Text>{t("auth_subtitle", { name })}</Text>
      <Flex
        direction={"column"}
        align={"start"}
        gap={"3"}
        mt={"6"}
        minWidth={"300px"}
      >
        <ButtonV2
          color={"mint"}
          size={"4"}
          variant={"soft"}
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            // do not await, the event will be sent when the user cones back from auth0
            auth.trackAuthStarted({
              has_contact_intent: false,
              auth_kind: "signup",
            });
            setPendingFavorite({ userId, sourcePage: currentPage });
            openSignupPopup();
          }}
        >
          {t("signup")}
        </ButtonV2>
        <ButtonV2
          color={"mint"}
          size={"4"}
          variant={"solid"}
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            // do not await, the event will be sent when the user cones back from auth0
            auth.trackAuthStarted({
              has_contact_intent: false,
              auth_kind: "login",
            });
            setPendingFavorite({ userId, sourcePage: currentPage });
            openLoginPopup();
          }}
        >
          {t("login")}
        </ButtonV2>
      </Flex>
    </Flex>
  );
}

export default FavoriteButtonAuthPrompt;
