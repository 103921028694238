import {
  FavoriteStatus,
  useFavorites,
} from "@components/Favorites/FavoritesContext";
import { Tooltip } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import React from "react";
import Favorite from "./Favorite";
import FavoriteButtonAuthPrompt, {
  FavoriteButtonAuthPromptProps,
} from "./FavoriteButtonAuthPrompt";

type FavoriteButtonProps = FavoriteButtonAuthPromptProps;

const FavoriteButton = (props: FavoriteButtonProps) => {
  const { favorites, addFavorite, removeFavorite, status } = useFavorites();
  const isFavorite = favorites
    .map((item) => item && item.toString())
    .includes(props.therapist_user_id.toString());
  const t = useTranslations("Favorites");
  const toggleFavorite = async (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isFavorite) {
      await removeFavorite({
        userId: props.therapist_user_id,
        sourcePage: props.currentPage,
      });
    } else {
      await addFavorite(
        {
          userId: props.therapist_user_id,
          sourcePage: props.currentPage,
        },
        false,
      );
    }
  };

  if (status === FavoriteStatus.NotLoggedIn) {
    return <FavoriteButtonAuthPrompt {...props} />;
  }

  const tooltipText = getTooltipKey(isFavorite, status);
  const disabled =
    status === FavoriteStatus.Loading ||
    status === FavoriteStatus.NotAvailable ||
    status === FavoriteStatus.WrongUserType;

  return (
    <Tooltip content={tooltipText ? t(tooltipText) : ""}>
      <Favorite
        tooltipText={tooltipText ? t(tooltipText) : ""}
        onClick={toggleFavorite}
        isDisabled={disabled}
        isFavorite={isFavorite}
        isCTA={props.isCTA}
      />
    </Tooltip>
  );
};

function getTooltipKey(isFavorite: boolean, status: FavoriteStatus) {
  switch (status) {
    case FavoriteStatus.Loading:
      return null;
    case FavoriteStatus.NotAvailable:
      return "tooltip_problem_loading_favorites";
    case FavoriteStatus.WrongUserType:
      return "tooltip_favorites_you_must_be_registered";
    default:
      return isFavorite
        ? "tooltip_remove_from_favorites"
        : "tooltip_save_to_favorites";
  }
}

export default FavoriteButton;
