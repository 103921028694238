import styles from "./DirectoryPicture.module.scss";
import PlayButton from "@/design-system/icons/PlayButton";
import React from "react";
import { Box } from "@radix-ui/themes";
import ProfileImage from "@components/Profile/Picture/ProfileImage";

type ProfilePictureInfo = {
  profilePictureUrl?: string;
  fullName: string;
};

type DirectoryPictureProps = {
  pictureInfo: ProfilePictureInfo | null;
  showPlayButton?: boolean;
  onClick?: () => void;
};
function DirectoryPicture(props: DirectoryPictureProps) {
  const pictureInfo = props.pictureInfo;
  return (
    <Box className={styles.imageWrapper}>
      <ProfileImage
        size={"100%"}
        alt={pictureInfo?.fullName || "Profile Picture"}
        src={pictureInfo?.profilePictureUrl}
      />
      {props.showPlayButton && (
        <div className={styles.playButtonHolder}>
          <PlayButton
            width={"100%"}
            height={"100%"}
            color={"#FFFFFF"}
            title={"Video Profile"}
          />
        </div>
      )}
    </Box>
  );
}

export { DirectoryPicture };
