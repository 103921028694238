import React from "react";

// Define the common props interface
interface SVGIconProps {
  width?: number | string;
  height?: number | string;
  color?: string;
  title?: string;
}

export function withCommonSVGIconProps(
  WrappedComponent: React.ComponentType<SVGIconProps>,
) {
  const WithCommonSVGIconProps = ({
    width = 24,
    height = 24,
    color = "currentColor",
    ...props
  }: SVGIconProps) => (
    <WrappedComponent width={width} height={height} color={color} {...props} />
  );

  // Assign a display name to help with debugging
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  WithCommonSVGIconProps.displayName = `withCommonSVGIconProps(${displayName})`;

  return WithCommonSVGIconProps;
}

export default withCommonSVGIconProps;
export type { SVGIconProps };
