import React from "react";
import {
  SVGIconProps,
  withCommonSVGIconProps,
} from "@/design-system/icons/withCommonSVGIconProps";

interface MySVGComponentProps extends SVGIconProps {
  title?: string;
}

const MySVGComponent: React.FC<MySVGComponentProps> = ({
  width,
  height,
  color,
  title,
}) => (
  <svg
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
    width={width}
    height={height}
  >
    {title && <title>{title}</title>}
    <path d="M 18.457 33.741 L 33.741 24 L 18.457 14.259 L 18.457 33.741 Z M 24.004 48 C 20.711 48 17.608 47.37 14.695 46.11 C 11.782 44.85 9.235 43.13 7.054 40.95 C 4.872 38.769 3.151 36.223 1.891 33.312 C 0.63 30.4 0 27.298 0 24.004 C 0 20.685 0.63 17.565 1.89 14.644 C 3.15 11.723 4.87 9.182 7.05 7.022 C 9.231 4.861 11.777 3.151 14.688 1.89 C 17.6 0.63 20.702 0 23.996 0 C 27.315 0 30.435 0.63 33.356 1.89 C 36.277 3.149 38.818 4.859 40.978 7.019 C 43.139 9.178 44.849 11.718 46.11 14.637 C 47.37 17.557 48 20.676 48 23.996 C 48 27.289 47.37 30.392 46.11 33.305 C 44.851 36.218 43.141 38.765 40.981 40.946 C 38.822 43.128 36.282 44.849 33.363 46.109 C 30.443 47.37 27.324 48 24.004 48 Z M 24 45.134 C 29.882 45.134 34.875 43.075 38.978 38.959 C 43.082 34.842 45.134 29.856 45.134 24 C 45.134 18.118 43.082 13.125 38.978 9.022 C 34.875 4.918 29.882 2.866 24 2.866 C 18.144 2.866 13.158 4.918 9.041 9.022 C 4.925 13.125 2.866 18.118 2.866 24 C 2.866 29.856 4.925 34.842 9.041 38.959 C 13.158 43.075 18.144 45.134 24 45.134 Z M 24 24 Z" />
  </svg>
);

MySVGComponent.displayName = "MySVGComponent";

export default withCommonSVGIconProps(MySVGComponent);
