import { TextV2 } from "@/design-system/components/text/TextV2";
import { PersonIcon } from "@radix-ui/react-icons";
import { Avatar, Box, Flex } from "@radix-ui/themes";
import React from "react";

type ProfileContactHeaderProps = {
  picture_url: string;
  full_name: string;
  jobtitle: string;
};

export function ProfileContactHeader({
  picture_url,
  full_name,
  jobtitle,
}: ProfileContactHeaderProps) {
  return (
    <Box p={"3"} width={"100%"}>
      <Flex gap={{ initial: "3", sm: "4" }}>
        <ProfileImage src={picture_url} />
        <Flex direction={"column"}>
          <TextV2 textStyle={"Headline M"} weightStyle={"medium"} clamp={2}>
            {full_name}
          </TextV2>
          <TextV2 textStyle={"Body XL"} weightStyle={"regular"} clamp={2}>
            {jobtitle}
          </TextV2>
        </Flex>
      </Flex>
    </Box>
  );
}

function ProfileImage({ src }: { src: string }) {
  return (
    <Avatar
      radius={"medium"}
      size={{ initial: "6", sm: "7" }}
      color={"teal"}
      fallback={<PersonIcon width={80} height={80} />}
      src={src}
      alt="Profile Picture"
    />
  );
}
