import React from "react";
import {
  SVGIconProps,
  withCommonSVGIconProps,
} from "@/design-system/icons/withCommonSVGIconProps";

const MySVGComponent: React.FC<SVGIconProps> = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 12.003 11.824 C 12.631 11.824 13.168 11.6 13.614 11.153 C 14.06 10.706 14.283 10.168 14.283 9.54 C 14.283 8.911 14.059 8.374 13.612 7.928 C 13.164 7.482 12.627 7.26 11.998 7.26 C 11.37 7.26 10.833 7.483 10.387 7.931 C 9.941 8.378 9.718 8.916 9.718 9.544 C 9.718 10.172 9.942 10.709 10.389 11.155 C 10.837 11.601 11.375 11.824 12.003 11.824 Z M 12.001 21.48 C 14.471 19.269 16.361 17.148 17.671 15.117 C 18.981 13.087 19.636 11.308 19.636 9.782 C 19.636 7.48 18.905 5.588 17.443 4.106 C 15.98 2.623 14.166 1.882 12.001 1.882 C 9.835 1.882 8.021 2.623 6.558 4.106 C 5.096 5.588 4.365 7.48 4.365 9.782 C 4.365 11.308 5.02 13.087 6.33 15.117 C 7.64 17.148 9.531 19.269 12.001 21.48 Z M 12.001 24 C 8.823 21.247 6.441 18.684 4.853 16.313 C 3.265 13.942 2.471 11.765 2.471 9.782 C 2.471 6.869 3.413 4.51 5.298 2.706 C 7.183 0.902 9.417 0 12.001 0 C 14.584 0 16.818 0.902 18.703 2.706 C 20.588 4.51 21.53 6.869 21.53 9.782 C 21.53 11.765 20.736 13.942 19.148 16.313 C 17.561 18.684 15.178 21.247 12.001 24 Z M 12.001 9.542 Z"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
    />
  </svg>
);

MySVGComponent.displayName = "MySVGComponent";

export default withCommonSVGIconProps(MySVGComponent);
