import { FullWidthPrimaryButton } from "@/design-system/components/PrimaryButton";
import { Box, Flex, IconButton, Tooltip } from "@radix-ui/themes";
import { Bookmark } from "lucide-react";
import { useTranslations } from "next-intl";
import React, { forwardRef } from "react";
import styles from "./Favorite.module.scss";

type FavoriteProps = {
  tooltipText: string;
  onClick: (event: React.MouseEvent) => void;
  isDisabled?: boolean;
  isFavorite?: boolean;
  isCTA?: boolean; // Profile pages
};

const Favorite = forwardRef<HTMLDivElement, FavoriteProps>(
  (
    {
      tooltipText,
      onClick,
      isDisabled = false,
      isFavorite = false,
      isCTA = false,
    },
    ref,
  ) => {
    const t = useTranslations("Favorites");

    return (
      <Tooltip ref={ref} content={tooltipText}>
        {isCTA ? (
          <Box>
            <Box display={{ initial: "none", sm: "block" }}>
              <FullWidthPrimaryButton
                variant={"outline"}
                onClick={onClick}
                disabled={isDisabled}
              >
                <Flex align={"center"} justify={"center"} gap={"1"}>
                  <Bookmark
                    width={"18"}
                    height={"18"}
                    strokeWidth={"2"}
                    stroke={"#255147"}
                    fill={isFavorite ? "#255147" : "none"}
                  />
                  {isFavorite ? t("saved") : t("save")}
                </Flex>
              </FullWidthPrimaryButton>
            </Box>
            <Box display={{ initial: "block", sm: "none" }}>
              <IconButton
                className={styles.iconContainer}
                radius="full"
                variant="soft"
                onClick={onClick}
                size={"3"}
                disabled={isDisabled}
              >
                <Bookmark
                  width={"24"}
                  height={"24"}
                  strokeWidth={"1.5"}
                  stroke={"#255147"}
                  fill={isFavorite ? "#255147" : "none"}
                />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <IconButton
            radius="full"
            variant="ghost"
            onClick={onClick}
            size={"2"}
            disabled={isDisabled}
          >
            <Bookmark
              width={"18"}
              height={"18"}
              strokeWidth={"2"}
              stroke={"#255147"}
              fill={isFavorite ? "#255147" : "none"}
            />
          </IconButton>
        )}
      </Tooltip>
    );
  },
);

Favorite.displayName = "Favorite";

export default Favorite;
