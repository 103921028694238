import ReactLoadingSkeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled, { css, keyframes } from "styled-components";

type SkeletonSizeProps = {
  height?: number | string;
  width?: number | string;
  className?: string;
};

type SkeletonProps =
  | ({ kind: "text" } & SkeletonSizeProps & { count?: number })
  | ({ kind: "circle" } & SkeletonSizeProps)
  | ({ kind: "box" } & SkeletonSizeProps);

function Skeleton(props: SkeletonProps) {
  switch (props.kind) {
    case "text":
      return <SkeletonText {...props} />;
    case "circle":
      return <SkeletonCircle {...props} />;
    case "box":
      return <SkeletonBox {...props} />;
    default:
      throw new Error(`Skeleton. Invalid kind:"${props["kind"]}"`);
  }
}

const SkeletonText = ReactLoadingSkeleton;

function SkeletonCircle(props: SkeletonSizeProps) {
  return <SkeletonWithDefaultLineHeight circle {...props} />;
}

function SkeletonBox(props: SkeletonSizeProps) {
  return (
    <ConatinerFullSize>
      <SkeletonWithDefaultLineHeight
        width={"100%"}
        height={"100%"}
        {...props}
      />
    </ConatinerFullSize>
  );
}

const keyframesShimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const SkeletonAnimation = css`
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;

  background-color: var(--base-color);

  position: relative;
  user-select: none;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */

  ::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
      90deg,
      var(--base-color),
      var(--highlight-color),
      var(--base-color)
    );
    transform: translateX(-100%);

    animation-name: ${keyframesShimmer};
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
`;

const SkeletonWithDefaultLineHeight = styled(ReactLoadingSkeleton)`
  line-height: revert;
`;

const ConatinerFullSize = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
`;

export { Skeleton, SkeletonAnimation };
