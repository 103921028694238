import React from "react";
import {
  SVGIconProps,
  withCommonSVGIconProps,
} from "@/design-system/icons/withCommonSVGIconProps";

const MySVGComponent: React.FC<SVGIconProps> = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 2.356 24 C 2.047 24 1.787 23.891 1.578 23.673 C 1.368 23.455 1.263 23.192 1.263 22.883 L 1.263 16.713 C 1.263 16.083 1.486 15.545 1.932 15.098 C 2.378 14.652 2.916 14.429 3.546 14.429 L 3.789 14.429 L 3.789 9.279 C 3.789 8.649 4.012 8.111 4.459 7.665 C 4.905 7.219 5.443 6.996 6.073 6.996 L 11.052 6.996 L 11.052 5.14 C 10.682 4.896 10.378 4.609 10.143 4.279 C 9.907 3.949 9.789 3.556 9.789 3.1 C 9.789 2.816 9.844 2.542 9.954 2.277 C 10.065 2.013 10.23 1.77 10.45 1.55 L 12 0 L 13.55 1.55 C 13.77 1.77 13.935 2.013 14.045 2.277 C 14.155 2.542 14.21 2.816 14.21 3.1 C 14.21 3.556 14.093 3.949 13.857 4.279 C 13.621 4.609 13.318 4.896 12.947 5.14 L 12.947 6.996 L 17.927 6.996 C 18.557 6.996 19.095 7.219 19.541 7.665 C 19.987 8.111 20.21 8.649 20.21 9.279 L 20.21 14.429 L 20.453 14.429 C 21.083 14.429 21.621 14.652 22.067 15.098 C 22.514 15.545 22.737 16.083 22.737 16.713 L 22.737 22.883 C 22.737 23.192 22.628 23.455 22.41 23.673 C 22.192 23.891 21.929 24 21.619 24 L 2.356 24 Z M 5.684 14.429 L 18.316 14.429 L 18.316 9.279 C 18.316 9.166 18.279 9.073 18.206 9 C 18.133 8.927 18.04 8.891 17.927 8.891 L 6.073 8.891 C 5.959 8.891 5.866 8.927 5.793 9 C 5.72 9.073 5.684 9.166 5.684 9.279 L 5.684 14.429 Z M 3.158 22.105 L 20.842 22.105 L 20.842 16.713 C 20.842 16.599 20.806 16.506 20.733 16.433 C 20.66 16.36 20.567 16.324 20.453 16.324 L 3.546 16.324 C 3.433 16.324 3.34 16.36 3.267 16.433 C 3.194 16.506 3.158 16.599 3.158 16.713 L 3.158 22.105 Z M 5.684 14.429 L 18.316 14.429 L 5.684 14.429 Z M 3.158 22.105 L 20.842 22.105 L 3.158 22.105 Z M 20.21 14.429 L 3.789 14.429 L 20.21 14.429 Z"
      transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"
    />
  </svg>
);

MySVGComponent.displayName = "MySVGComponent";

export default withCommonSVGIconProps(MySVGComponent);
