import { useTranslations } from "next-intl";
import { TextV2 } from "@/design-system/components/text/TextV2";
import getSymbolFromCurrency from "currency-symbol-map";
import styles from "@components/Search/List/DirectoryCard.module.scss";
import { Flex } from "@radix-ui/themes";
import { ChatBubbleIcon, LaptopIcon, PersonIcon } from "@radix-ui/react-icons";
import LocationIcon from "@/design-system/icons/LocationIcon";
import { Skeleton } from "@/design-system/components/Skeleton";
import { useMoment } from "@/hooks/useMoment";
import { WideDesktopView } from "@/design-system/responsive-helpers/WideDesktopView";
import CakeIcon from "@/design-system/icons/CakeIcon";
import React, { PropsWithChildren } from "react";

function SessionPrice({
  priceAmount,
  priceCurrency,
}: {
  priceAmount: number;
  priceCurrency: string;
}) {
  const t = useTranslations("Search.directory_card");
  return (
    <ProfileFooterTag allowTruncation={false}>
      <TextV2 textStyle={"Body S"} style={{ fontWeight: "medium" }}>
        {getSymbolFromCurrency(priceCurrency)}
      </TextV2>

      <TextV2 textStyle={"Body S"} className={"truncate " + styles.variant1}>
        {t("price_per_session", {
          price: priceAmount,
        })}
      </TextV2>
      <TextV2 textStyle={"Body S"} className={styles.variant2And3}>
        {priceAmount}
      </TextV2>
    </ProfileFooterTag>
  );
}

function SessionFormats({
  supportsInPersonTherapy,
  supportsVideoTherapy,
  supportsTextTherapy,
}: {
  supportsInPersonTherapy: boolean;
  supportsVideoTherapy: boolean;
  supportsTextTherapy: boolean;
}) {
  const t = useTranslations("Search.directory_card.session_formats");

  const formatsTexts = [
    supportsInPersonTherapy && t("in_person"),
    supportsVideoTherapy && t("online"),
    supportsTextTherapy && t("text"),
  ].filter(Boolean);

  if (formatsTexts.length === 0) {
    return <></>;
  } else {
    let formatsText = "";
    if (formatsTexts.length === 1) {
      formatsText = t("formatters.one", { format: formatsTexts[0] });
    } else if (formatsTexts.length === 2) {
      formatsText = t("formatters.two", {
        format1: formatsTexts[0],
        format2: formatsTexts[1],
      });
    } else {
      formatsText = t("formatters.three", {
        format1: formatsTexts[0],
        format2: formatsTexts[1],
        format3: formatsTexts[2],
      });
    }
    return (
      <ProfileFooterTag allowTruncation={true}>
        <Flex direction={"row"} wrap={"nowrap"} gap={"1"}>
          {supportsInPersonTherapy && <PersonIcon />}
          {supportsVideoTherapy && <LaptopIcon />}
          {supportsTextTherapy && <ChatBubbleIcon />}
        </Flex>
        <TextV2 textStyle={"Body S"} className={styles.footerTagFormatsText}>
          {formatsText}
        </TextV2>
      </ProfileFooterTag>
    );
  }
}

function SessionCity({ name }: { name?: string }) {
  return (
    <>
      {name && (
        <ProfileFooterTag allowTruncation={true}>
          <LocationIcon width={12} height={12} />
          <TextV2 textStyle={"Body S"} className={"truncate"}>
            {name}
          </TextV2>
        </ProfileFooterTag>
      )}
      {!name && <Skeleton kind="text" width={100} />}
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function YearsOfExperience({ monthYearStarted }: { monthYearStarted: string }) {
  const moment = useMoment();
  const givenDate = moment(monthYearStarted);
  const currentDate = moment();
  const yearsDifference = currentDate.diff(givenDate, "years");

  if (yearsDifference < 1) {
    return null;
  }

  return (
    <WideDesktopView>
      <ProfileFooterTag allowTruncation={true}>
        <CakeIcon width={12} height={12} />
        <TextV2 textStyle={"Body S"} className={"truncate"}>
          {yearsDifference} years
        </TextV2>
      </ProfileFooterTag>
    </WideDesktopView>
  );
}

type ProfileFooterTagProps = {
  allowTruncation: boolean;
};
function ProfileFooterTag({
  children,
  allowTruncation = true,
}: PropsWithChildren<ProfileFooterTagProps>) {
  const className = allowTruncation
    ? styles.footerTag
    : styles.footerTagNoMinWidth;

  return (
    <Flex
      dir={"column"}
      align={"center"}
      gap={"2"}
      px={"3"}
      py={"1"}
      wrap={"nowrap"}
      flexShrink={"1"}
      className={className}
    >
      {children}
    </Flex>
  );
}

export { SessionPrice, SessionFormats, SessionCity, YearsOfExperience };
