import React, { useState, useCallback, useEffect, useMemo } from "react";
import Image from "next/image";
import { Flex, Skeleton } from "@radix-ui/themes";
import { PersonIcon } from "@radix-ui/react-icons";
import { ArmchairIcon } from "lucide-react";
import resourceGreen1 from "public/images/resource_green_1.png";
import resourceGreen2 from "public/images/resource_green_2.png";
import resourceGreen3 from "public/images/resource_green_3.png";
import resourcePink1 from "public/images/resource_pink_1.png";
import resourcePink2 from "public/images/resource_pink_2.png";
import resourcePink3 from "public/images/resource_pink_3.png";

export type ProfileImagePhotoType =
  | "person"
  | "gallery"
  | "course"
  | "event"
  | "publication";

interface ProfileImageProps {
  src?: string;
  alt: string;
  size?: number | string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  photoType?: ProfileImagePhotoType;
  photoIndex?: number;
  aspectRatio?: number;
  priority?: boolean;
}

const ProfileImage = React.memo(
  ({
    src,
    alt,
    size = "100%",
    className,
    style,
    onClick,
    photoType = "person",
    photoIndex,
    aspectRatio,
    priority = false,
  }: ProfileImageProps) => {
    const [source, setSource] = useState(src);
    const [isLoading, setLoading] = useState(!!src);
    const [hasError, setError] = useState(false);

    const handleLoad = useCallback(() => setLoading(false), []);
    const handleError = useCallback(() => {
      setLoading(false);
      setError(true);
    }, []);

    const pinkResources = useMemo(() => [
      resourcePink1.src,
      resourcePink2.src,
      resourcePink3.src,
    ], []);
    
    const greenResources = useMemo(() => [
      resourceGreen1.src,
      resourceGreen2.src,
      resourceGreen3.src,
    ], []);

    useEffect(() => {
      if (src) {
        setSource(src);
        setLoading(!!src);
        setError(false);
      } else if (
        ["course", "event", "publication"].includes(photoType) &&
        typeof photoIndex === "number"
      ) {
        let resourceSource = undefined;
        if (photoType === "event") {
          resourceSource = pinkResources[photoIndex % 2]; // Alternate between pink images 1 and 2
        } else if (photoType === "course") {
          resourceSource =
            photoIndex % 2 === 0 ? pinkResources[2] : pinkResources[1]; // Alternate between pink images 3 and 2
        } else if (photoType === "publication") {
          resourceSource = greenResources[photoIndex % 3]; // Alternate between green images 1, 2, 3
        }
        setSource(resourceSource);
        setLoading(!!resourceSource);
        setError(false);
      }
    }, [src, photoType, photoIndex, pinkResources, greenResources]);

    return (
      <Skeleton
        loading={isLoading}
        style={{ width: size, height: size, borderRadius: "12px" }}
      >
        <div
          style={{
            width: "100%",
            height: aspectRatio ? "auto" : "100%",
            paddingTop: aspectRatio ? `${(1 / aspectRatio) * 100}%` : 0,
            position: "relative",
          }}
        >
          {!source || hasError ? (
            <FallbackIconHolder size={size}>
              {photoType === "person" && (
                <PersonIcon
                  width={"50%"}
                  height={"50%"}
                  color="var(--colorV2-grey-medium)"
                />
              )}
              {photoType === "gallery" && (
                <ArmchairIcon
                  width={"50%"}
                  height={"50%"}
                  color="var(--colorV2-grey-medium)"
                />
              )}
            </FallbackIconHolder>
          ) : (
            <Image
              src={source}
              width={0}
              height={0}
              sizes="100vw"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "12px",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
                ...style,
              }}
              alt={alt}
              onLoad={handleLoad}
              onError={handleError}
              className={className}
              onClick={onClick}
              priority={priority}
            />
          )}
        </div>
      </Skeleton>
    );
  },
);

function FallbackIconHolder({
  size,
  children,
}: {
  size: number | string;
  children: React.ReactNode;
}) {
  return (
    <Flex
      style={{
        width: size,
        height: size,
        borderRadius: "12px",
        borderColor: "var(--colorV2-grey-medium)",
        borderWidth: "2px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      p={"5"}
      align={"center"}
      justify={"center"}
    >
      {children}
    </Flex>
  );
}

ProfileImage.displayName = "ProfileImage";

export default ProfileImage;
